.bg-gray-500 {
  background-color: var(--bs-gray-500) !important
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important
}

.w-200 {
  width: 200px;
}

// md
@media (min-width: 768px) {
  .w-md-25 { width: 25% !important; }
  .w-md-50 { width: 50% !important; }
  .w-md-75 { width: 75% !important; }
}

// lg
@media (min-width: 992px) {
  .w-lg-25 { width: 25% !important; }
  .w-lg-50 { width: 50% !important; }
  .w-lg-75 { width: 75% !important; }
}
