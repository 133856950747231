.ui.table.payslips-list th,
.ui.table.payslips-list td {
  text-align: center;
}

.payslips-list {
  .emp-data{
    background-color: #80808052 !important;
  }
  .deductions{
    background-color: #ff00001f !important;
  }
  .allowances{
    background-color: #0080002b !important;
  }
  .benefits{
    background-color: #ffff003d !important;
  }
  .salary{
    background-color: #0586fb36 !important;
  }
  .overtime{
    background-color: #9d13b936 !important;
  }
}

.no-wrap {
  white-space: nowrap;
}
