body.rtl {
  .text-start {
    text-align: right !important;
  }

  .text-end {
    text-align: left !important;
  }

  .me-0 {
    margin-right: initial !important;
    margin-left: 0 !important;
  }

  .me-1 {
    margin-right: initial !important;
    margin-left: 0.25rem !important;
  }

  .me-2 {
    margin-right: initial !important;
    margin-left: 0.5rem !important;
  }

  .me-3 {
    margin-right: initial !important;
    margin-left: 1rem !important;
  }

  .me-4 {
    margin-right: initial !important;
    margin-left: 1.5rem !important;
  }

  .me-5 {
    margin-right: initial !important;
    margin-left: 3rem !important;
  }

  .ms-0 {
    margin-left: initial !important;
    margin-right: 0 !important;
  }

  .ms-1 {
    margin-left: initial !important;
    margin-right: 0.25rem !important;
  }

  .ms-2 {
    margin-left: initial !important;
    margin-right: 0.5rem !important;
  }

  .ms-3 {
    margin-left: initial !important;
    margin-right: 1rem !important;
  }

  .ms-4 {
    margin-left: initial !important;
    margin-right: 1.5rem !important;
  }

  .ms-5 {
    margin-left: initial !important;
    margin-right: 3rem !important;
  }


  i.icon {
    margin-inline-end: 0.25rem;
    margin-right: 0;
  }

  .ui.dropdown {
    text-align: right;
  }

  .ui.labeled.icon.button>.icon, .ui.labeled.icon.buttons>.button>.icon {
    right: 0;
    left: auto;
  }

  .ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
    padding-right: 4.07142857em !important;
    padding-left: 1.5em !important;
  }

  .date_picker {
    text-align: right;
  }

  .ui.icon.input>input {
    padding-right: 1em !important;
    padding-left: 2.67142857em !important;
    text-align: right;
  }

  .ui.icon.input>i.icon {
    left: 0;
    right: auto;
  }

}
